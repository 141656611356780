<template>
    <div class="login-page">
        <Header></Header>
        <div class="login-header d-none" :style="{'background-image': `url(${baseURL}/assets/images/bg-img-main.png)`}">
        </div>
        <section class="container login-section login-signup-container">
            <div class="row w-100 min-height-430 justify-content-center align-items-center">
                <div class="col-md-6 col-12 content-area px-0">
                    <div class="login-form-holder">
                        <div class="inner ">
                            <div class="login-form">
                                <router-view/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer></Footer>
    </div>
</template>
<script>

import Header from './Header.vue'
import Footer from './Footer'

export default {
  name: 'LoginLayout',
  data () {
    return {
      baseURL: process.env.VUE_APP_BASE_URL
    }
  },
  components: {
    Header,
    Footer
  }
}
</script>

